import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

import './assets/css/reset.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faHand } from '@fortawesome/free-solid-svg-icons';

//구글 애널리틱스 통계
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID;
ReactGA.initialize(gaTrackingId);

const history = createBrowserHistory();
history.listen((response) => {
  console.log(response.location.pathname);
  ReactGA.set({ page: response.location.pathname });
  ReactGA.pageview(response.location.pathname);
});
//

const Header = styled.header`
  position:fixed;
  z-index:9999;
  margin:30px 0 0 30px
`

const PotalMain = styled.section`
  width:100%;
  height:100%;
`

let PotalWrap = styled.div`
  overflow:hidden;


.react-player > video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}



`;

let CoverOverlay = styled.div `
  position:absolute;
  width:100%;
  height:100%;
  left:0;
  top:0;
  background-color:#000;
  opacity:${(props) => (props.$bgOverlay === "/video/portal_bg_video1.mp4" ? '0.2' : '0.5')};;
`

let BackgroundVideoWrap = styled.div`
  position:absolute;
  z-index:999;
  top:3%;
  right:3%;

  button {
    background:#eee;
    width:5px;
    height:5px;
    border-radius:20px;
    margin-right:10px;
    line-height:5px;
  }

  .bgChange {
    background:#ff8c0d;
  }
`

let TitleWrap = styled.div `
  position:absolute;
  z-index:9999;
  top:35%;
  left:50%;
  transform: translate(-50%, -50%);
  width:100%;
  text-align:center;
  color:${(props) => props.color};
  font-size:3rem;
  line-height:1.5;
  text-align:center;
  word-break:keep-all;

  @media screen and (max-width: 640px) {
    top:32%;
    font-size:1.5rem;
  }
`

let StrongTxt = styled.span `
  color:${(props) => props.color};
  font-size:${(props) => props.fontSize};
  font-weight:${(props) => props.fontWeight};

  @media screen and (max-width: 640px) {
    font-size:1.5rem;
  }
`

let PortalSiteWrap = styled.div`
  position:absolute;
  z-index:9999;
  top:65%;
  left:50%;
  transform: translate(-50%, -50%);
  display:flex;
  justify-content: center;
  height:350px;

  @media screen and (max-width: 640px) {
    width:95%;
    height:auto;
    //flex-flow:column;
  }
`
let SiteLinkBox = styled(Link) `
  position: relative;
  width:300px;
  height:250px;
  min-width:80px;
  margin:0.5rem 1rem;
  display:flex;
  justify-content:space-between;
  flex-flow:column;
  padding:3rem;
  // box-shadow : 4px 3px 4px 1px #ddd;
  border-radius:20px;
  background-color:${(props) => props.$bgColor};
  transition: height .4s ease-in-out;

  &.toLink:hover {
    height: 350px;
    .txt {
      opacity:1;
      transform: translateY(0);
    }

  }

  @media screen and (max-width: 1000px) {
    width:220px;
    height:220px;
    padding:2rem;
  }

  @media screen and (max-width: 640px) {
    width:30%;
    height:140px;
    margin:10px;
    padding:10px;
    justify-content:center;

    &.toLink:hover {
      height: 140px;
    }
  }
`
let SlideArrow = styled(FontAwesomeIcon)`
  transition: .5s ease-in-out;

  &:hover {
    transform: translateX(-20px); /* 원하는 만큼의 이동 거리 지정 */
  }
`
let TextRight = styled.div `
  text-align: right;

  @media screen and (max-width: 640px) {
    font-size:0.6rem;
  }
`
let Logo = styled.h2 `
  width:160px;
  height:70px;

  > img {
    width:100%;
  }

  &.introLogo {
    height:45px;
  }

  @media screen and (max-width: 1000px) {
    width:140px;
  }

  @media screen and (max-width: 640px) {
    width:80px;
    height:50px;

    &.introLogo {
      height:20px;
    }
  }
`

let Ptxt = styled.p `
  font-size:${(props) => props.fontSize};
  font-weight:${(props) => props.fontWeight};
  color:${(props) => props.color ? props.color : '#222'};
  line-height:1.4;

  > span {
    display:inline-block;
    font-size:${(props) => props.fontSize};
    padding-top:10px;
    line-height:1.2;

    > b {
      color: #023E8A;
      font-size:1.25rem;
    }
  }

  @media screen and (max-width: 1000px) {

    >span {
      font-size:1rem;
      padding-top:5px;

      > b {
        font-size:1.1rem;
      }
    }

  }


  @media screen and (max-width: 640px) {
    font-size:0.6rem;

    >span {
      font-size:0.6rem;
      padding-top:5px;

      > b {
        font-size:0.68rem;
      }
  }
`

let Spantxt = styled.span `
  font-size:${(props) => props.fontSize};

  position: absolute;
  bottom: 30%;
  font-weight:400;
  color:#7d7d7d;
  line-height:1.4;
  margin-top:0.5rem;
  padding-right:3rem;
  transform: translateY(80%);
  opacity:0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;

  @media screen and (max-width: 640px) {
    display:none;
    font-size:0.875rem;
  }
`

export default function Portal() {

  const [bgVideo, setBgVideo] = useState("/video/portal_bg_video1.mp4");
  
  return(
    <>
    <Header><img src="/img/ksta_logo_stxt_w_512.png" alt="ksta" style={{width:"200px"}}/></Header>
    <PotalMain>
      <PotalWrap>
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={process.env.PUBLIC_URL + bgVideo}
            playing
            muted
            loop
            />
        </div>
        <CoverOverlay $bgOverlay={bgVideo}></CoverOverlay> 
        <BackgroundVideoWrap>
          <button className={bgVideo === "/video/portal_bg_video1.mp4" ?  "bgChange" : ""} onClick={()=> setBgVideo("/video/portal_bg_video1.mp4")}></button>
          <button className={bgVideo === "/video/portal_bg_video2.mp4" ?  "bgChange" : ""} onClick={()=> setBgVideo("/video/portal_bg_video2.mp4")}></button>
        </BackgroundVideoWrap>
        <TitleWrap color="#fff">
          <StrongTxt fontSize="3rem" fontWeight="600" color="#fff">안전하고 건강한 삶</StrongTxt>을 위한 스마트 솔루션!<br/>
          <StrongTxt fontSize="3rem" fontWeight="600" color="#fff">한국안전기술협회</StrongTxt>가 대한민국의 안전을 책임집니다<br/>
        </TitleWrap>
        <PortalSiteWrap>
          <SiteLinkBox $bgColor="#EDFCFF" className="noneLink">
            <div>
            <Logo className="introLogo"><img src="/img/welcome_logo_tn.png" alt="ksta" /></Logo>
              <Ptxt fontSize="1.1rem" fontWeight="500" color="#222">
                <span><b>한국안전기술협회</b>를</span><br/>
                <span>방문해주셔서 감사합니다</span><br/>
                </Ptxt>
            </div>
          </SiteLinkBox>
          <SiteLinkBox $bgColor="#f7f7ff" to="http://info.kstar.or.kr" target="_blank" className="toLink">
            <div>
              <Logo><img src="/img/ksta_logo.png" alt="ksta" /></Logo>
              <Ptxt  fontSize="1.2rem" fontWeight="500">한국안전기술협회<br/>사회적협동조합</Ptxt>
            </div>
            <Spantxt fontSize="0.9rem" className="txt" style={{bottom:"23%"}}>안전사업 발굴과 사회공헌의 최전선에서 한국안전기술협회가 세상에 모범이 되는 기업가치를 실천하겠습니다.</Spantxt>
            <TextRight><SlideArrow icon={faArrowRight} size="2x" className="i" /></TextRight>
          </SiteLinkBox>
          <SiteLinkBox $bgColor="#f2fff0" to="http://cps.kstar.or.kr" target="_blank" className="toLink">
            <div>
              <Logo><img src="/img/cps_logo.png" alt="ksta" /></Logo>
              <Ptxt  fontSize="1.2rem" fontWeight="500">어린이놀이시설<br/>안전관리시스템</Ptxt>
            </div>
            <Spantxt fontSize="0.9rem" className="txt">어린이놀이시설관리를 전산화하여 편리하고 안전하게 시설을 관리합니다</Spantxt>
            <TextRight><SlideArrow icon={faArrowRight} size="2x" className="i" /></TextRight>
          </SiteLinkBox>
        </PortalSiteWrap>
      </PotalWrap>
    </PotalMain>
    </>
  )

}